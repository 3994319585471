import React from "react"
import { Flex, Box, Text } from "rebass"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title"

const ContentSection = ({ title, image, children }) => (
  <Box py={[4, 4]}>
    <Text
      color="#a02118"
      fontSize={3}>
      <div style={{
        display: 'inline-block',
        borderStyle: 'solid',
        borderColor: '#a02118',
        borderWidth: '0 2px 2px 0',
        transform: 'rotate(-45deg)',
        padding: 4,
        marginBottom: 2
      }} />
      <div style={{
        display: 'inline-block',
        borderStyle: 'solid',
        borderColor: '#a02118',
        borderWidth: '0 2px 2px 0',
        transform: 'rotate(-45deg)',
        padding: 4,
        marginRight: 9,
        marginBottom: 2,
        marginLeft: -4
      }} />
      {title}
    </Text>
    <Flex flexWrap='wrap' mx={-3} my={[0, 4]}>
      <Box px={3} py={[4, 0]}>
        {children}
      </Box>
    </Flex>
  </Box>
);

const FaqPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <Box width={[1, 2/3]} px={[3, 5]} py={5}>
      <Title>FAQ</Title>
      <ContentSection title="Ist Akupunktur schmerzhaft?">
        Im Mittelpunkt der Chinesischen Medizin steht die Vorstellung von einer im Körper fließenden Lebensenergie (Qi), deren harmonisches Fließen im Falle von Krankheiten gestört ist. Die Akupunktur reguliert den Qi-Fluss und bringt den Körper wieder in seinen natürlichen, ausgeglichenen Zustand. Dabei werden hauchdünne Nadeln in sog. Meridian- oder Akupunkturpunkte gesetzt. Durch die Reizung dieser Punkten werden Hormonspiegel verändert, so dass vermehrt körpereigene Endorphine gegen Schmerzen ausgeschüttet und die Selbstheilungskräfte des Körpers aktiviert werden.
      </ContentSection>
      <hr />
      <ContentSection title="Für wen eignet sich Akupunktur?">
        Zur körperlichen Stimulation werden in Abhängigkeit von Konstitution und Erkrankung des Patienten nicht nur Nadeln, sondern auch Moxakegel oder Zigarren und Schröpfköpfe verwendet. Die Moxibustion ist das Anwärmen von gewissen Punkten mit getrocknetem Beifuß. Bei der Schröpfbehandlung werden die Schröpfgläser durch Feuer von innen erwärmt und auf die Haut gesetzt, so dass ein Unterdruck entsteht. Dadurch wird die Durchblutung gefördert, der Stoffwechsel verbessert sowie Schlacken abtransportiert.
      </ContentSection>
      <hr />
      <ContentSection title="Wie lange dauert eine Akupunkturbehandlung und wie oft wird behandelt?">
        Die chinesische Diätetik ist ein wichtiger Baustein im Gesamtgefüge der Chinesischen Medizin. Sie ermöglicht es, den Organismus in einer Weise zu stärken, dass Krankheit erst gar nicht entsteht. Diese Ernährungslehre richtet sich nach dem Temperatur- und Geschmacksverhalten von Nahrungsmitteln sowie ihrer energetischen Wirkung. Eingebettet in das chinesische Diagnose- und Therapieprinzip lässt sich so für jeden Patienten ein ganz individueller Ernährungsplan erstellen.
      </ContentSection>
      <hr />
      <ContentSection title="Können Nebenwirkungen auftreten?">
      In der Chinesischen Medizin werden Krankheiten niemals isoliert, sondern immer im Zusammenhang betrachtet. Dabei wird der Mensch in seiner Gesamtheit (Körper, Seele, Geist) und unter Einbeziehung seiner Umwelt und Lebensgewohnheiten betrachtet.
      </ContentSection>
      <hr />
      <ContentSection title="Kann Akupunktur mit schulmedizinischen Therapien kombiniert werden?">
        Die chinesische Diätetik ist ein wichtiger Baustein im Gesamtgefüge der Chinesischen Medizin. Sie ermöglicht es, den Organismus in einer Weise zu stärken, dass Krankheit erst gar nicht entsteht. Diese Ernährungslehre richtet sich nach dem Temperatur- und Geschmacksverhalten von Nahrungsmitteln sowie ihrer energetischen Wirkung. Eingebettet in das chinesische Diagnose- und Therapieprinzip lässt sich so für jeden Patienten ein ganz individueller Ernährungsplan erstellen.
      </ContentSection>
      <hr />
      <ContentSection title="Werden die Akupunkturnadeln wieder verwendet?">
      In der Chinesischen Medizin werden Krankheiten niemals isoliert, sondern immer im Zusammenhang betrachtet. Dabei wird der Mensch in seiner Gesamtheit (Körper, Seele, Geist) und unter Einbeziehung seiner Umwelt und Lebensgewohnheiten betrachtet.
      </ContentSection>
      <hr />
      <ContentSection title="Was sollten Sie vor der Behandlung beachten?">
        Die chinesische Diätetik ist ein wichtiger Baustein im Gesamtgefüge der Chinesischen Medizin. Sie ermöglicht es, den Organismus in einer Weise zu stärken, dass Krankheit erst gar nicht entsteht. Diese Ernährungslehre richtet sich nach dem Temperatur- und Geschmacksverhalten von Nahrungsmitteln sowie ihrer energetischen Wirkung. Eingebettet in das chinesische Diagnose- und Therapieprinzip lässt sich so für jeden Patienten ein ganz individueller Ernährungsplan erstellen.
      </ContentSection>
      <hr />
      <ContentSection title="Werden die Kosten von der Krankenkasse übernommen?">
      In der Chinesischen Medizin werden Krankheiten niemals isoliert, sondern immer im Zusammenhang betrachtet. Dabei wird der Mensch in seiner Gesamtheit (Körper, Seele, Geist) und unter Einbeziehung seiner Umwelt und Lebensgewohnheiten betrachtet.
      </ContentSection>
    </Box>
  </Layout>
)

export default FaqPage
